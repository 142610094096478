<!-- src/App.vue -->
<script setup lang="ts">
import { computed } from 'vue'
import { RouterView } from 'vue-router'
import { useAuthStore } from './stores/auth'
import TenantLayout from './layouts/TenantLayout.vue'
import BlankLayout from './layouts/BlankLayout.vue'

const authStore = useAuthStore()

const currentLayout = computed(() => {
  return authStore.isAuthenticated ? TenantLayout : BlankLayout
})
</script>

<template>
  <component :is="currentLayout">
    <RouterView />
  </component>
</template>

<style scoped>
/* Your scoped styles here */
</style>