<script setup lang="ts">
import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();

const greeting = computed(() => {
  const username = authStore.username;
  if (username) {
    const greetings = [
      `Welcome back, ${username}!`,
      `Hey there, ${username}!`,
      `Great to see you, ${username}!`,
      `What's cooking, ${username}?`,
      `Ready for action, ${username}?`
    ];
    return greetings[Math.floor(Math.random() * greetings.length)];
  }
  return "Welcome!";
});
</script>

<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-card-title class="text-h4 text-center py-4">
            Home
          </v-card-title>
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="12" class="text-center">
                <v-icon icon="mdi-home" size="x-large" color="primary" class="mb-4"></v-icon>
                <h2 class="text-h5 mb-6">{{ greeting }}</h2>
                <p class="text-body-1">Welcome to your dashboard. Here you can manage your account and access various features.</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center pb-6">
            <v-btn color="primary" to="/about">
              Explore More
              <v-icon end icon="mdi-arrow-right"></v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>