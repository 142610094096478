import './assets/global/main.scss'
import 'floating-vue/dist/style.css'
import '@mdi/font/css/materialdesignicons.css'
import "vue-toastification/dist/index.css"

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { VDateInput } from 'vuetify/labs/components'
import type { PluginOptions } from 'vue-toastification'
import { POSITION } from 'vue-toastification'
import router from './router'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Toast from 'vue-toastification'
import FloatingVue from 'floating-vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'



const toastOptions: PluginOptions = {
  position: POSITION.TOP_RIGHT
}

const vuetify = createVuetify({
  components: {
    ...components,
    VDateInput
  },
  directives,
  theme:{
    defaultTheme: 'plazaDark',
    themes: {
      plazaLight: {
        dark: false,
        colors: {
          primary: '#334C3B',
          secondary: '#5D896B',
          info: 'rgb(86,54,32)',
          error: 'rgb(13,23,45)',
          warning: '#FF6666',
          accent: '#5046E6',
          success: 'rgb(54,86,90)'
        }
      },
      plazaDark: {
        dark: true,
        colors: {
          primary: '#334C3B',
          secondary: '#5D896B',
          background: '#121212',
          surface: '#1E1E1E',
          info: '#64B5F6',
          error: '#FF5252',
          warning: '#FFD54F',
          accent: '#7C4DFF',
          success: '#81C784',
          'on-background': '#FFFFFF',
          'on-surface': '#FFFFFF',
          'on-primary': '#FFFFFF',
          'on-secondary': '#FFFFFF',
          'on-error': '#FFFFFF',
          'on-info': '#000000',
          'on-success': '#000000',
          'on-warning': '#000000'
        }
      },
      rachelTheme: {
        dark: false,
        colors: {
          primary: '#8d85e3',
          secondary: '#8970b7',
          info: 'rgb(86,54,32)',
          error: 'rgb(13,23,45)',
          warning: '#FF6666',
          accent: '#5046E6',
          success: 'rgb(54,86,90)'
        }
      }

    }
  },
  defaults: {
    global: {
      font: {
        family: 'Source Sans 3'
      }
    }}
})


const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(Toast, toastOptions)
app.use(FloatingVue)
app.mount('#app')
